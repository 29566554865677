<!-- @format -->
<template>
    <div class="columns has-background-primary has-text-white audio-player">
        <div class="column is-2 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
            <div class="is-flex">
                <span class="icon is-medium mx-2" :class="canPlay && 'is-clickable'" @click="skipBackward">
                    <fa-icon icon="undo" />
                </span>
                <span class="icon is-medium mx-2" :class="canPlay && 'is-clickable'" @click="togglePlay">
                    <fa-icon v-if="!canPlay" icon="circle-notch" spin size="lg" />
                    <fa-icon v-else-if="isPlaying" icon="pause" />
                    <fa-icon v-else icon="play" />
                </span>
                <span class="icon is-medium mx-2" :class="canPlay && 'is-clickable'" @click="skipForward">
                    <fa-icon icon="redo" />
                </span>
            </div>
        </div>
        <div class="column">
            <div class="is-flex">
                <p class="is-flex-grow-1">{{ title }}</p>
                <span class="icon ml-2 is-clickable" @click="close">
                    <fa-icon icon="times" />
                </span>
            </div>
            <div class="is-flex is-flex-direction-row is-align-items-center">
                <div class="track is-flex-grow-1 mr-2">
                    <input
                        :value="position"
                        type="range"
                        class="track is-clickable"
                        :class="canPlay && 'is-clickable'"
                        :max="duration"
                        @change="seek"
                        @input="seekStarted"
                    />
                </div>
                <p class="timer is-size-7">{{ timer }}</p>
            </div>
            <div v-if="errors.length" class="is-flex">
                <p class="help is-danger">{{ errors[0] }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { formatDuration } from '@/helpers/media';
export default {
    props: {
        url: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        playImmediately: {
            type: Boolean,
            default: false
        }
    },

    audio: null,

    data() {
        return {
            canPlay: false,
            isPlaying: false,
            isSeeking: false,
            position: 0,
            duration: 0,
            errors: []
        };
    },

    computed: {
        timer() {
            return formatTimer(this.position, this.duration);
        }
    },

    watch: {
        url: {
            handler(val) {
                // If an audio is already created, reset
                if (this.$options.audio) {
                    this.$options.audio.pause();
                    this.canPlay = false;
                    this.position = 0;
                    this.duration = 0;
                }
                this.$options.audio = new Audio(val);
                const audio = this.$options.audio;
                audio.load(); // Required in iOS Safari
                audio.addEventListener(
                    'canplay',
                    () => {
                        if (!this.canPlay) {
                            this.canPlay = true;
                            if (this.playImmediately) {
                                audio.play();
                            }
                        }
                        this.duration = audio.duration;
                    },
                    false
                );
                audio.addEventListener(
                    'play',
                    () => {
                        this.isPlaying = true;
                        this.errors = [];
                    },
                    false
                );
                audio.addEventListener(
                    'pause',
                    () => {
                        this.isPlaying = false;
                    },
                    false
                );
                audio.addEventListener(
                    'timeupdate',
                    () => {
                        if (this.isSeeking) {
                            return;
                        }
                        this.position = audio.currentTime;
                    },
                    false
                );
                audio.addEventListener(
                    'error',
                    () => {
                        this.errors = ['There was a problem loading the audio content.'];
                    },
                    false
                );
            },
            immediate: true
        }
    },

    beforeDestroy() {
        this.$options.audio?.pause();
        this.$options.audio = null;
    },

    methods: {
        togglePlay() {
            if (!this.canPlay) {
                return;
            }
            this.isPlaying = !this.isPlaying;
            const audio = this.$options.audio;

            if (audio.paused) {
                audio.play();
            } else {
                audio.pause();
            }
        },
        seekStarted() {
            this.isSeeking = true;
        },
        seek(event) {
            if (!this.canPlay) {
                return;
            }
            const value = event.target.value;
            this.$options.audio.currentTime = Number(value);
            this.isSeeking = false;
        },
        skipBackward() {
            if (!this.canPlay) {
                return;
            }
            this.$options.audio.currentTime = this.$options.audio.currentTime - 10;
        },
        skipForward() {
            if (!this.canPlay) {
                return;
            }
            this.$options.audio.currentTime = this.$options.audio.currentTime + 10;
        },
        close() {
            this.$emit('close');
        }
    }
};

function formatTimer(position, duration) {
    return `${formatDuration(position)} / ${formatDuration(duration - position)}`;
}
</script>
