<!-- @format -->
<template>
    <a class="is-flex is-align-items-center" href="/">
        <img
            class="main-logo"
            :src="headerImage.url"
            :alt="headerAlt"
            :title="headerTitle"
            :width="headerImage.width"
            :height="headerImage.height"
        />
    </a>
</template>

<script>
import { mapState } from 'vuex';
import { mediaMixin } from '@/mixins/MediaMixin.js';

export default {
    mixins: [mediaMixin],

    computed: {
        ...mapState({
            mainPublication: (state) => state.publication.mainPublication
        }),
        /**
         * Return site image URL
         *
         * @return string
         */
        headerImage() {
            // @todo 2020-04-24  MJL  We should get the image from publication table or related model, but for now - I'm just hard-coding it
            let image = 'NewsAtomicLogo-171215-trans.png';
            let width = 800;
            let height = 151;
            switch (this.mainPublication && this.mainPublication.slug ? this.mainPublication.slug : null) {
                case 'shawangunk-journal':
                    image = 'ShawangunkJournal-2021.jpg';
                    width = 385; //504;
                    height = 79;
                    break;
                case 'kingston-wire':
                    image = 'KingstonWireLogo-200909-2-blueK-151.png';
                    width = 608;
                    height = 151;
                    break;
                case 'dundalk-herald':
                    image = 'DH-FA ONLINE LOGO 278x65 REV11x.png';
                    //image = 'DH-FA-NABanner.jpg';
                    //image = 'DH-banner-top.jpg';
                    width = 732;
                    height = 167;
                    break;
                case 'sc-democrat':
                    image = 'SCDemocrat-logo-header.jpg';
                    break;
            }
            return {
                url: this.imageUrl(image),
                width,
                height
            };
        },
        /**
         * Return alt tag text
         *
         * @return string
         */
        headerAlt() {
            return this.mainPublication && this.mainPublication.name
                ? this.mainPublication.name + ' Home'
                : 'NewsAtomic Home';
        },
        /**
         * Return title text
         *
         * @return string
         */
        headerTitle() {
            return this.mainPublication && this.mainPublication.name ? this.mainPublication.name : 'NewsAtomic';
        }
    }
};
</script>
