<!-- @format -->
<template>
    <nav class="is-flex is-justify-content-center na-header">
        <div class="container" :class="{ 'is-fluid': !clientIsMobile }">
            <div class="is-flex is-flex-grow-1 is-justify-content-space-between">
                <nav-left />
                <nav-center />
                <nav-right />
            </div>
        </div>
    </nav>
</template>

<script>
import NavLeft from './Left.vue';
import NavCenter from './Center.vue';
import NavRight from './Right.vue';

export default {
    name: 'NavHead',

    components: {
        NavLeft,
        NavCenter,
        NavRight
    }
};
</script>
