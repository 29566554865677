<!-- @format -->
<template>
    <div
        v-click-outside="clickedOutside"
        class="navbar na-navbar-top-left is-transparent pl-1"
        role="navigation"
        aria-label="left navigation"
    >
        <div class="navbar-item na-navbar-item has-dropdown" :class="{ 'is-active': isVisible }">
            <a class="navbar-link na-navbar-link is-arrowless" @click="isVisible = !isVisible">
                <fa-icon class="is-hidden-mobile" icon="bars" size="2x" />
                <fa-icon class="is-hidden-tablet" icon="bars" size="lg" />
            </a>

            <div class="navbar-dropdown na-navbar-dropdown">
                <a class="navbar-item na-navbar-item has-text-weight-bold" @click="isSearchVisible = !isSearchVisible"
                    ><fa-icon icon="search" />&nbsp; Search</a
                >

                <div v-if="isSearchVisible" class="navbar-item">
                    <simple-search input-class="na-search-input" autofocus @close="closeSearch" />
                </div>

                <hr v-if="isShowNotLoggedInOptions" class="navbar-divider" />

                <hr v-if="isShowReaderOptions" class="navbar-divider" />

                <a class="navbar-item na-navbar-item" @click="showPublicationPicker = true"
                    ><fa-icon :icon="['fac', 'newspaper-old']" />&nbsp; Publications</a
                >

                <hr class="navbar-divider" />

                <publication-picker v-if="showPublicationPicker" @close="showPublicationPicker = false" />

                <sections-main
                    @cancelled="sectionCancelled"
                    @is-managing="sectionManaging"
                    @section-selected="sectionSelected"
                />

                <hr v-if="isShawangunkJournal" class="navbar-divider" />
                <router-link
                    v-if="isShawangunkJournal"
                    class="navbar-item na-navbar-item"
                    :to="{ name: 'archive-link-selector' }"
                    @click.native="clickedOutside"
                    >Archive 2006 &ndash; 2018</router-link
                >
            </div>
        </div>
    </div>
</template>

<script>
import SectionsMain from '@/components/Content/Sections/Index.vue';
import SimpleSearch from '@/components/Content/Search/SimpleSearch.vue';
import PublicationPicker from '@/components/Content/PublicationPicker/Index.vue';

export default {
    components: {
        SectionsMain,
        SimpleSearch,
        PublicationPicker
    },

    data() {
        return {
            isVisible: false,
            isSearchVisible: false,
            showPublicationPicker: false,
            isSectionManagementActive: false // Hokey fix to stop click outside from firing when section management is active (tried with middleware for the click handler, but that seems too varied)
        };
    },

    computed: {
        isKingstonWire() {
            const mainPublication = this.$store.state.publication.mainPublication;
            return mainPublication && mainPublication.slug && mainPublication.slug === 'kingston-wire';
        },
        isShawangunkJournal() {
            const mainPublication = this.$store.state.publication.mainPublication;
            return mainPublication && mainPublication.slug && mainPublication.slug === 'shawangunk-journal';
        },
        isNewsAtomic() {
            return !this.isKingstonWire && !this.isShawangunkJournal;
        },
        /**
         * Determine if we should show search, section, et.al. options
         * Only show if logged in, and NOT on the admin page
         *
         * @return {Boolean}
         */
        isShowReaderOptions() {
            return this.userIsLoggedIn;
        },
        /**
         * Determine if we should show simple code option - basically if user isn't logged in
         *
         * @return {Boolean}
         */
        isShowNotLoggedInOptions() {
            return !this.userIsLoggedIn;
        },
        /**
         * Determine if click outside directive should be honored
         *
         * @return {Boolean}
         */
        honorClickOutside() {
            return this.isVisible && !this.isSectionManagementActive && !this.showPublicationPicker;
        }
    },

    methods: {
        /**
         * A section was selected.
         *
         * @param {Object} section Section that was selected
         */
        sectionSelected() {
            this.isVisible = false; // Hide nav bar after selection
        },
        /**
         * User has cancelled section (closed window, no changes)
         */
        sectionCancelled() {
            this.isSearchVisible = false; // Close search box
            this.isVisible = false; // Hide nav bar
        },
        sectionManaging(flag) {
            this.isSectionManagementActive = flag;
        },
        /**
         * User clicked outside the nav, hide it
         */
        clickedOutside() {
            if (this.honorClickOutside) {
                this.isVisible = false;
            }
        },
        closeSearch() {
            this.isSearchVisible = false;
            this.isVisible = false;
        }
    }
};
</script>
