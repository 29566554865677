/**
 * @format
 * Help related API functions.
 * Abstracting these out from DataMixin.js.
 */
import axios from 'axios';

/**
 * Create (send) a help message
 *
 * @param {Object} data Comment data
 *
 * @return Promise for resolve, reject
 */
export const createHelpMessage = function (data) {
    return axios
        .post(naRoutes.createHelpMessage, data)
        .then((response) => response.data);
};
