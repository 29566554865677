<!-- @format -->
<template>
    <standard-modal :show-close-button="false" @close="$emit('close')">
        <div class="box has-text-grey-dark">
            <div class="is-flex is-justify-content-space-between">
                <p class="is-size-5 has-text-weight-bold">Choose Publications</p>
                <button type="button" class="button is-white" @click="$emit('close')">
                    <span class="icon is-small"><fa-icon icon="times-circle" /></span>
                </button>
            </div>

            <div class="content">
                <p class="na-publication-picker-explain">Click on the publication name for more information.</p>
            </div>

            <table class="table is-fullwidth na-publication-picker-table">
                <tbody>
                    <div v-for="option in optionPublications" :key="option.id" style="border: 1px solid #ebebeb">
                        <tr class="field">
                            <td>
                                <input
                                    :id="checkboxName(option)"
                                    type="checkbox"
                                    class="switch is-rounded"
                                    :checked="selectedPublications.includes(option.value)"
                                    @click="togglePublication(option.value)"
                                />
                                <label :for="checkboxName(option)"></label>
                                <!-- Here to satisfy bulma-extension switch requirement for adjacent label, but we need label in next column -->
                            </td>
                            <td>
                                <label
                                    class="is-inline-flex is-align-items-center has-text-success"
                                    :class="{ 'is-clickable': option.value !== null && option.aboutUs }"
                                    @click="
                                        option.value !== null && option.aboutUs
                                            ? (option.showInfo = !option.showInfo)
                                            : null
                                    "
                                >
                                    <publication-icon
                                        v-if="option.icon"
                                        class="is-inline-block"
                                        :icon="option.icon"
                                        :alt-text-fallback="option.text"
                                    />
                                    <span>{{ option.text }}</span>
                                </label>
                                <span
                                    v-if="option.value !== null && option.aboutUs"
                                    class="is-clickable"
                                    @click="option.showInfo = !option.showInfo"
                                >
                                    &hellip;
                                </span>
                            </td>
                        </tr>
                        <div v-if="option.showInfo && option.aboutUs" class="columns">
                            <div class="column">
                                <div class="na-publication-picker-info-box">
                                    <div class="content is-small">
                                        <p v-if="option.aboutUs">
                                            {{ option.aboutUs }}
                                        </p>
                                        <p v-else>
                                            <em>No content.</em>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tbody>
            </table>

            <button type="button" class="button is-success" :class="{ 'is-loading': isBusy }" @click="submit">
                Set Publication Preferences
            </button>
        </div>
    </standard-modal>
</template>

<script>
import { userDataSavePublicationPreferences, userDataLoadProfile } from '@/services/user.js';
import { readPublications } from '@/services/publication.js';
import PublicationIcon from '@/components/Common/PublicationIcon.vue';

export default {
    components: {
        PublicationIcon
    },

    data() {
        return {
            isBusy: false,
            userProfile: null,
            errors: [],
            optionPublications: [],
            selectedPublications: []
        };
    },

    async created() {
        this.isBusy = true;
        try {
            await Promise.all([this.loadPublications(), this.loadUserProfile()]);
            this.isBusy = false;
        } catch (error) {
            this.isBusy = false;
        }
    },

    methods: {
        checkboxName(option) {
            return `checkbox-${option && option.id ? option.id : 'all'}`;
        },

        async loadUserProfile() {
            if (!this.userIsLoggedIn) {
                this.selectedPublications = JSON.parse(window.naShared.storage.getItem('selectedPublications')) || [];
                return;
            }
            const response = await userDataLoadProfile();
            if (!response.success) {
                this.errors = response.errors;
                return;
            }
            this.userProfile = response.userProfile;

            // No selected, then toggle to selected all
            if (!this.userProfile.post_preferences.length) {
                this.selectedPublications = [null];
                return;
            }
            this.userProfile.post_preferences.forEach((item) => {
                if (item.type && item.type.key === 'userPostPreferenceTypePublication') {
                    this.selectedPublications.push(item.option_id);
                }
            });
        },

        async loadPublications() {
            const response = await readPublications({
                sort: {
                    field: 'name',
                    direction: 'asc'
                },
                filter: {
                    showAllStatusTypes: true
                }
            });
            if (!response.success) {
                this.errors = response.errors;
            } else {
                this.optionPublications = [
                    {
                        id: null,
                        value: null,
                        text: 'ALL',
                        icon: null,
                        showInfo: false
                    }
                ];
                response.publications.forEach((item) => {
                    this.optionPublications.push({
                        id: item.id,
                        value: item.id,
                        text: item.name,
                        aboutUs: item.about_us,
                        icon: this.getPublicationMedia(item, 'mediaTypeIcon')[0],
                        showInfo: false
                    });
                });
            }
        },

        /**
         * Return publication media matching the requested media type key
         *
         * @param {Object} post      Post object
         * @param {String} mediaType Media type key (e.g. 'mediaTypeIcon')
         *
         * @return {Array}
         */
        getPublicationMedia(publication, mediaType) {
            if (publication && publication.media && publication.media.length) {
                return publication.media.filter((item) => {
                    return item && item.media_type && item.media_type.key === mediaType;
                });
            } else {
                return [];
            }
        },

        togglePublication(value) {
            if (value === null) {
                if (this.selectedPublications.includes(null)) {
                    this.selectedPublications = [];
                    return;
                }
                this.selectedPublications = [null];
                return;
            }

            // Remove "All" first, then proceed to add the value
            if (this.selectedPublications.includes(null)) {
                this.selectedPublications = [];
            }

            const index = this.selectedPublications.indexOf(value);
            if (index !== -1) {
                this.selectedPublications.splice(index, 1);
            } else {
                this.selectedPublications.push(value);
            }

            // No more selected, then toggle back to selected all
            if (!this.selectedPublications.length) {
                this.selectedPublications = [null];
            }
        },

        async submit() {
            if (!this.userIsLoggedIn) {
                this.submitUnauth();
                return;
            }
            this.isBusy = true;
            try {
                const response = await userDataSavePublicationPreferences({
                    publications: this.selectedPublications
                });
                if (!response.success) {
                    if (response.errors) {
                        this.errors = response.errors;
                    }
                    this.isBusy = false;
                } else {
                    // Force ignorePreferences to false after setting the publication preference
                    window.naShared.storage.setItem('ignorePreferences', JSON.stringify(false));
                    window.location.reload();
                }
            } catch (error) {
                this.isBusy = false;
            }
        },

        /**
         * For unauth visitor of the site, save the publication setting in localstorage
         */
        submitUnauth() {
            if (this.selectedPublications.includes(null)) {
                this.selectedPublications = [];
            }
            window.naShared.storage.setItem('selectedPublications', JSON.stringify(this.selectedPublications));
            window.location.reload();
        }
    }
};
</script>
