<!-- @format -->
<template>
    <div>
        <router-link class="navbar-item na-navbar-item" :to="{ name: 'home' }" exact @click.native="close"
            >Home</router-link
        >

        <!-- <router-link -->
        <!--     v-if="userIsLoggedIn" -->
        <!--     class="navbar-item na-navbar-item" -->
        <!--     :to="{ name: 'section', params: { sectionRoute: 'whats-new' } }" -->
        <!--     >What's New</router-link -->
        <!-- > -->

        <!-- <router-link -->
        <!--     v-for="section in customSections" -->
        <!--     :key="`${section.id}-c`" -->
        <!--     class="navbar-item na-navbar-item" -->
        <!--     :to="{ name: 'post-viewer-section', params: { sectionId: section.id } }" -->
        <!--     @click.native="sectionSelected(section)" -->
        <!--     >{{ section.name }}</router-link -->
        <!-- > -->

        <router-link
            v-for="section in activeSections"
            :key="`${section.id}-s`"
            class="navbar-item na-navbar-item"
            :to="{ name: 'section', params: { sectionRoute: section.route } }"
            @click.native="sectionSelected(section)"
            >{{ section.name }}</router-link
        >

        <!-- <template v-if="mainPublication === null"> -->
        <!--     <router-link -->
        <!--         class="navbar-item na-navbar-item na-section-atom-news" -->
        <!--         :to="{ name: 'section', params: { sectionRoute: 'atom-news' } }" -->
        <!--         @click.native="sectionSelected(section)" -->
        <!--         >NewsAtomic News</router-link -->
        <!--     > -->
        <!-- </template> -->

        <!-- <template v-if="userIsLoggedIn"> -->
        <!--     <hr v-if="activeSections.length" class="navbar-divider" /> -->
        <!--  -->
        <!--     <a class="navbar-item na-navbar-item" @click="isManaging = true" -->
        <!--         ><fa-icon class="mr-2" icon="cog" size="lg" />Customize</a -->
        <!--     > -->
        <!--  -->
        <!--     <manage-sections -->
        <!--         v-if="isManaging" -->
        <!--         @cancelled="cancelled" -->
        <!--         @added="added" -->
        <!--         @updated="updated" -->
        <!--         @removed="removed" -->
        <!--         @sorted="sorted" -->
        <!--     /> -->
        <!-- </template> -->
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// import ManageSections from './Management/Index.vue';

export default {
    components: {
        // ManageSections
    },

    data() {
        return {
            isBusy: false,
            isLoaded: false,
            isManaging: false
        };
    },

    computed: {
        ...mapState({
            customSections: (state) => state.user.sections.data,
            sections: (state) => state.user.sections.data,
            mainPublication: (state) => state.publication.mainPublication
        }),

        /**
         * Return only those sections that are active
         *
         * @return {Array} Active sections
         */
        activeSections() {
            return this.sections.filter((item) => item.is_active);
        }
    },

    watch: {
        isManaging() {
            this.$emit('is-managing', this.isManaging);
        }
    },

    methods: {
        ...mapActions({
            vuexloadSections: 'user/loadSections'
        }),

        /**
         * Load sections
         */
        async loadSections() {
            this.isBusy = true;
            const data = {
                includeInactive: true
            };
            await this.vuexloadSections(data);
            this.isBusy = false;
        },

        /**
         * User closes section
         */
        cancelled() {
            this.isManaging = false;
        },

        /**
         * A section was added, refresh
         *
         * @param {Object} data Section (data) that was added.  This is *NOT* the same as a section object
         */
        added() {
            this.loadSections();
        },
        /**
         * A section was updated, refresh
         *
         * @param {Object} data Section (data) that was updated.  This is *NOT* the same as a section object
         */
        updated() {
            this.loadSections();
        },
        /**
         * A section was removed, refresh
         *
         * @param {Object} section Section that was removed
         */
        removed() {
            this.loadSections();
        },
        /**
         * Section sort order was changed, refresh
         */
        sorted() {
            this.loadSections();
        },
        /**
         * A section was selected.
         *
         * @param {Object} section Section that was selected
         */
        sectionSelected(section) {
            this.$emit('section-selected', section);
        },
        close() {
            this.$emit('cancelled');
        }
    }
};
</script>
