<!-- @format -->
<template>
    <div
        v-click-outside="clickedOutside"
        class="navbar na-navbar-top-right is-transparent pr-1"
        role="navigation"
        aria-label="right navigation"
    >
        <div class="navbar-item na-navbar-item has-dropdown" :class="{ 'is-active': isVisible }">
            <a class="navbar-link na-navbar-link is-arrowless" @click="isVisible = !isVisible">
                <fa-icon class="is-hidden-mobile" icon="user-circle" size="2x" />
                <fa-icon class="is-hidden-tablet" icon="user-circle" size="lg" />
            </a>

            <div class="navbar-dropdown na-navbar-dropdown is-right">
                <div v-if="userIdentity" class="navbar-item na-navbar-item has-text-weight-bold pb-0">
                    {{ userIdentity }}
                </div>
                <div v-if="userRole" class="navbar-item na-navbar-item pt-0 na-navbar-item-padded-left">
                    {{ userRole }}
                </div>
                <!--
                <a v-if="canAccessAdministration" class="navbar-item na-navbar-item" @click="fireTestEvent"
                    >Fire Test Event to GA</a
                >
                -->
                <a
                    v-if="isImpersonating"
                    class="navbar-item na-navbar-item pt-0 na-navbar-item-padded-left"
                    @click="userImpersonateOff()"
                    >Impersonating user (disable)</a
                >
                <a v-if="canAccessAdministration" class="navbar-item na-navbar-item" :href="adminHome"
                    >Administration</a
                >
                <router-link
                    v-if="isKingstonWire"
                    class="navbar-item na-navbar-item"
                    :class="[isKingstonWire ? 'sj-blue-background' : '']"
                    active-class="is-active"
                    :to="{ name: 'kwsubscribe' }"
                    >Subscribe</router-link
                >
                <router-link
                    v-if="isShawangunkJournal"
                    class="navbar-item na-navbar-item sj-blue-background"
                    active-class="is-active"
                    :to="{ name: 'sjsubscribe' }"
                    >Subscribe</router-link
                >
                <a v-if="isShawangunkJournal" class="navbar-item na-navbar-item sj-blue-background" @click="signUpSJ"
                    >E-Newsletter Sign Up</a
                >
                <router-link
                    v-if="isShawangunkJournal"
                    class="navbar-item na-navbar-item sj-blue-background"
                    active-class="is-active"
                    :to="{ name: 'sj-print-subscription-renewal' }"
                    >Print Subscription Renewals</router-link
                >
                <router-link
                    v-if="isShawangunkJournal"
                    class="navbar-item na-navbar-item sj-blue-background"
                    active-class="is-active"
                    :to="{ name: 'sj-make-contribution' }"
                    >Make A Contribution</router-link
                >
                <router-link
                    v-if="isNewsAtomic"
                    class="navbar-item na-navbar-item"
                    active-class="is-active"
                    :to="{ name: 'subscribe' }"
                    >Subscribe</router-link
                >
                <router-link
                    class="navbar-item na-navbar-item"
                    :class="[isShawangunkJournal || isKingstonWire ? 'sj-blue-background' : '']"
                    active-class="is-active"
                    :to="{ name: 'ad' }"
                    >Advertise</router-link
                >
                <router-link
                    v-if="isKingstonWire"
                    class="navbar-item na-navbar-item sj-blue-background"
                    active-class="is-active"
                    :to="{ name: 'kw-make-contribution' }"
                    >Donate</router-link
                >
                <router-link
                    v-if="userIsLoggedIn"
                    class="navbar-item na-navbar-item"
                    active-class="is-active"
                    :to="{ name: 'account' }"
                    >My Account</router-link
                >
                <hr class="navbar-divider" />
                <social-media-icons-group class="navbar-item na-navbar-item" icon-class="mr-1"
                ></social-media-icons-group>
                <router-link
                    class="navbar-item na-navbar-item"
                    active-class="is-active"
                    :to="{ name: 'about' }"
                    @click.ctrl="isShowVersion = !isShowVersion"
                    >About Us</router-link
                >
                <a
                    v-if="isShowVersion"
                    class="navbar-item na-navbar-item pt-0 na-navbar-item-padded-left"
                    @click="isShowVersion = !isShowVersion"
                    >{{ version }}</a
                >
                <router-link class="navbar-item na-navbar-item" active-class="is-active" :to="{ name: 'contact' }"
                    >Contact Us</router-link
                >
                <router-link class="navbar-item na-navbar-item" active-class="is-active" :to="{ name: 'tip' }"
                    >Submit News Tip</router-link
                >
                <router-link class="navbar-item na-navbar-item" active-class="is-active" :to="{ name: 'faq' }"
                    >FAQ</router-link
                >
                <router-link class="navbar-item na-navbar-item" active-class="is-active" :to="{ name: 'terms' }"
                    >Privacy / Terms of Service</router-link
                >
                <hr class="navbar-divider" />
                <a v-if="userIsLoggedIn" class="navbar-item na-navbar-item" @click="isHelpVisible = !isHelpVisible"
                    >Help</a
                >
                <div v-if="isHelpVisible" class="navbar-item na-navbar-item">
                    <simple-help
                        wrapper-class="na-help"
                        placeholder="Email Message"
                        :include-button="true"
                        button-label="Send"
                        additional-message="Or call 845-647-9190 M-F 9-5 Eastern"
                        @close="
                            isHelpVisible = false;
                            isVisible = false;
                        "
                    />
                </div>
                <a v-if="userIsLoggedIn" class="navbar-item na-navbar-item" :href="logoutRoute">Log Out</a>
                <router-link
                    v-if="!userIsLoggedIn"
                    class="navbar-item na-navbar-item"
                    :to="{ name: 'home', query: { auth_view: 'login' } }"
                    >Log In</router-link
                >
                <router-link
                    v-if="!userIsLoggedIn"
                    class="navbar-item na-navbar-item"
                    :to="{ name: 'home', query: { auth_view: 'signup' } }"
                    >Sign Up</router-link
                >
            </div>
        </div>
    </div>
</template>

<script>
import { analyticsEventFireTest, analyticsEventFireNewsletter } from '@/services/analytics_events';
import SimpleHelp from '@/components/Content/Help/SimpleHelp.vue';
import SocialMediaIconsGroup from '@/components/Content/Social-Media/Icons/Group.vue';

export default {
    components: {
        SimpleHelp,
        SocialMediaIconsGroup
    },

    data() {
        return {
            isVisible: false,
            isHelpVisible: false,
            isShowVersion: false,
            unregisterRouteHook: null
        };
    },

    computed: {
        isKingstonWire() {
            const mainPublication = this.$store.state.publication.mainPublication;
            return mainPublication && mainPublication.slug && mainPublication.slug === 'kingston-wire';
        },
        isShawangunkJournal() {
            const mainPublication = this.$store.state.publication.mainPublication;
            return mainPublication && mainPublication.slug && mainPublication.slug === 'shawangunk-journal';
        },
        isNewsAtomic() {
            return !this.isKingstonWire && !this.isShawangunkJournal;
        },
        /**
         * Return user identity
         *
         * @return {mixed} Identity (or null if not logged in)
         */
        userIdentity() {
            if (this.userCurrent) {
                return this.userCurrent.first_name ? this.userCurrent.first_name : null;
            } else {
                return null;
            }
        },
        /**
         * Return user primary role
         *
         * @return {mixed} Role (or null if not logged in)
         */
        userRole() {
            return this.userType;
        },
        /**
         * Determine if we're impersonating this user
         *
         * @return {Boolean}
         */
        isImpersonating() {
            return this.userIsImpersonating;
        },
        /**
         * Return version number of NA
         */
        version() {
            return window.naVersion ? 'NewsAtomic v' + window.naVersion : 'NewsAtomic v?';
        },
        adminHome() {
            return window.naRoutes.adminHome;
        },
        logoutRoute() {
            return window.naRoutes.logout;
        }
    },

    created() {
        this.unregisterRouteHook = this.$router.afterEach(() => {
            this.isVisible = false;
        });
    },

    beforeDestroy() {
        this.unregisterRouteHook();
    },

    methods: {
        /**
         * User clicked outside the nav, hide it
         */
        clickedOutside() {
            if (this.isVisible) {
                this.isHelpVisible = false;
                this.isVisible = false;
            }
        },

        signUpSJ() {
            window.ml_account('webforms', '4629115', 'q4g4i5', 'show');
            // I don't think there's a way to guarantee that they actually performed the sign-up.  We just sent them there.
            analyticsEventFireNewsletter();
            this.clickedOutside();  // Close the nav bar
        },

        fireTestEvent() {
            console.log('DEBUG: fireTestEvent');
            analyticsEventFireTest();
        }
    }
};
</script>
