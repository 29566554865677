<!-- @format -->
<template>
    <div :class="wrapperClass">
        <textarea
            v-model="content"
            class="textarea"
            :placeholder="placeholder"
            autofocus="autofocus"
            rows="1"
            cols="10"
            @keyup.esc="close"
        />
        <a v-if="includeButton" class="button" :disabled="isDisabled" @click="doSubmit">{{ buttonLabel }}</a>
        <input-error field="content" :errors="errors" />
        <span v-if="additionalMessage">{{ additionalMessage }}</span>
    </div>
</template>

<script>
import InputError from '@/components/Common/InputError.vue';
import { createHelpMessage } from '@/services/help';

export default {
    components: {
        InputError
    },

    props: {
        wrapperClass: {
            type: String,
            default: 'na-simple-help'
        },
        placeholder: {
            type: String,
            default: 'enter message'
        },
        includeButton: {
            type: Boolean,
            default: false
        },
        buttonLabel: {
            type: String,
            default: 'Go'
        },
        additionalMessage: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            content: null,
            errors: [],
            isBusy: false
        };
    },

    computed: {
        isDisabled() {
            return (
                this.isBusy || this.content === null || typeof this.content !== 'string' || !this.content.trim().length
            );
        }
    },

    methods: {
        /**
         * Clear input
         */
        clear() {
            this.content = null;
        },
        /**
         * Submit help
         */
        async doSubmit() {
            this.errors = [];
            if (this.isDisabled) {
                return;
            }
            if (this.content !== null && typeof this.content === 'string' && this.content.trim().length) {
                this.isBusy = true;
                const data = {
                    content: this.content
                };
                try {
                    const response = await createHelpMessage(data);
                    if (response.success) {
                        this.clear();
                        this.isBusy = false;
                        this.$emit('close');
                    } else {
                        this.errors = response.errors;
                    }
                } catch (error) {
                    this.isBusy = false;
                    this.errors = error?.response.data.errors || [error.message];
                }
            }
        },
        /**
         * Signal to close
         */
        close() {
            this.errors = [];
            this.clear();
            this.$emit('close');
        }
    }
};
</script>
