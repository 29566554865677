<!-- @format -->
<template>
    <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label" :class="labelClasses">
                {{ label }}
            </label>
        </div>
        <div class="field-body">
            <div class="field" :class="fieldClasses">
                <slot />
                <input-error :field="name" :errors="errors" />
            </div>
        </div>
    </div>
</template>

<script>
import InputError from './InputError.vue';
export default {
    components: {
        InputError
    },

    props: {
        classes: {
            type: String,
            default: ''
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        errors: {
            type: Array,
            default: () => []
        }
    },

    computed: {
        fieldClasses() {
            return [this.classes, this.hasInputError(this.errors, this.name)];
        },
        labelClasses() {
            const classes = []
            if (this.isRequired) {
                classes.push('na-is-required');
            }
            return classes;
        }
    }
};
</script>
